import common from "../../utill/Common";

class RechargeService {
  report = async (
    token,
    from_date,
    to_date,
    status,
    product_id,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/recharge/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        status,
        product_id,
        user_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  disputeReport = async (
    token,
    from_date,
    to_date,
    status,
    dispute_status,
    product_id,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/recharge/dispute_report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        status,
        dispute_status,
        product_id,
        user_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  summary = async (token, from_date, to_date, product_id, user_id, days) => {
    const url = common.baseUrl + "admin/recharge/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date, product_id, user_id, days }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  xlsReport = async (token, from_date, to_date) => {
    const url = common.baseUrl + "admin/recharge/xls_report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateTxnStatus = async (token, id, status, operator_id, remark) => {
    const url = common.baseUrl + "admin/recharge/update_txn_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id, status, operator_id, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateDisputeTxnStatus = async (token, id, status, remark) => {
    const url = common.baseUrl + "admin/recharge/update_dispute_txn_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id, status, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  apiDisputeRequest = async (token, id) => {
    const url = common.baseUrl + "admin/recharge/api_dispute_request";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  apiStatusCheck = async (token, id) => {
    const url = common.baseUrl + "admin/recharge/api_status_check";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  addRechargeApi = async (
    token,
    name,
    urlx,
    user_id,
    password,
    tokenx,
    status
  ) => {
    const url = common.baseUrl + "admin/recharge/api/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        name,
        url: urlx,
        user_id,
        password,
        token: tokenx,
        status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateRechargeApi = async (
    token,
    id,
    name,
    urlx,
    user_id,
    password,
    tokenx,
    status
  ) => {
    const url = common.baseUrl + "admin/recharge/api/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        name,
        url: urlx,
        user_id,
        password,
        token: tokenx,
        status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAllRechargeApi = async (token) => {
    const url = common.baseUrl + "admin/recharge/api/fetch_all";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteRechargeApi = async (token, id) => {
    const url = common.baseUrl + "admin/recharge/api/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAllOperator = async (token) => {
    const url = common.baseUrl + "admin/recharge/operator/fetch_all";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  switchRechargeApi = async (
    token,
    id,
    recharge_api_id,
    recharge_api_id2,
    recharge_api_id3
  ) => {
    const url = common.baseUrl + "admin/recharge/operator/switch_recharge_api";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        recharge_api_id,
        recharge_api_id2,
        recharge_api_id3,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RechargeService();
