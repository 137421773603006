import React, { useRef, useState, useEffect, useContext } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Swal from "sweetalert2";
import { ThemeProvider, createTheme } from "@mui/material";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import RechargeService from "../../../services/Admin/RechargeService";
import ProductService from "../../../services/Admin/ProductService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const SwitchRechargeApi = () => {
  const loadingCtx = useContext(LoadingContext);

  const [products, setProducts] = React.useState();
  const [rechargeApis, setRechargeApis] = React.useState();

  useEffect(() => {
    fetchAllOperator();
    fetchApis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllOperator = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await RechargeService.fetchAllOperator(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setProducts(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const fetchApis = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await RechargeService.fetchAllRechargeApi(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setRechargeApis(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const apiName1Ref = useRef();
  const apiName2Ref = useRef();
  const apiName3Ref = useRef();

  const columns = [
    {
      title: "Operator Name",
      field: "name",
      editable: "never",
    },
    {
      title: "API (Route 1)",
      field: "rechargeApi1.name",
      editComponent: (data) => (
        <Autocomplete
          options={rechargeApis}
          defaultValue={
            data.rowData.rechargeApi1
              ? rechargeApis.find(
                  (v) => v.name === data.rowData.rechargeApi1.name
                )
              : ""
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: 300 }}
          // onChange={selectedApiIdChangeHandler}
          //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
          renderInput={(params) => (
            <TextField
              inputRef={apiName1Ref}
              {...params}
              label="Select Recharge API"
              margin="normal"
            />
          )}
        />
      ),
    },
    {
      title: "API (Route 2)",
      field: "rechargeApi2.name",
      editComponent: (data) => (
        <Autocomplete
          options={rechargeApis}
          defaultValue={
            data.rowData.rechargeApi2
              ? rechargeApis.find(
                  (v) => v.name === data.rowData.rechargeApi2.name
                )
              : ""
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: 300 }}
          // onChange={selectedApiIdChangeHandler}
          //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
          renderInput={(params) => (
            <TextField
              inputRef={apiName2Ref}
              {...params}
              label="Select Recharge API"
              margin="normal"
            />
          )}
        />
      ),
    },
    {
      title: "API (Route 3)",
      field: "rechargeApi3.name",
      editComponent: (data) => (
        <Autocomplete
          options={rechargeApis}
          defaultValue={
            data.rowData.rechargeApi3
              ? rechargeApis.find(
                  (v) => v.name === data.rowData.rechargeApi3.name
                )
              : ""
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: 300 }}
          // onChange={selectedApiIdChangeHandler}
          //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
          renderInput={(params) => (
            <TextField
              inputRef={apiName3Ref}
              {...params}
              label="Select Recharge API"
              margin="normal"
            />
          )}
        />
      ),
    },
  ];

  const defaultMaterialTheme = createTheme({});

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Switch Recharge API">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Switch Recharge API</h3>
                  </div>

                  <div class="card-body mb-5">
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <MaterialTable
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                        }}
                        columns={columns}
                        data={products}
                        options={{
                          search: false,
                          paging: false,
                          showTitle: false,
                        }}
                        editable={{
                          onRowUpdate: (newData, oldData) =>
                            new Promise(async (resolve, reject) => {
                              // console.log(newData);
                              // console.log(oldData);

                              loadingCtx.startLoading();

                              const apiName1 = apiName1Ref.current.value;
                              const fData1 = rechargeApis.find((element) => {
                                return element.name === apiName1;
                              });

                              const apiName2 = apiName2Ref.current.value;
                              const fData2 = rechargeApis.find((element) => {
                                return element.name === apiName2;
                              });

                              const apiName3 = apiName3Ref.current.value;
                              const fData3 = rechargeApis.find((element) => {
                                return element.name === apiName3;
                              });

                              const token = AuthService.getAccessToken();

                              RechargeService.switchRechargeApi(
                                token,
                                oldData.id,
                                fData1.id,
                                fData2.id,
                                fData3.id
                              )
                                .then((data) => {
                                  loadingCtx.stopLoading();
                                  fetchAllOperator();
                                })
                                .catch((error) => {
                                  loadingCtx.stopLoading();
                                  fetchAllOperator();
                                });

                              resolve();
                            }),

                          isDeletable: (rowData) => false,
                        }}
                      />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default SwitchRechargeApi;
